document.write(`
<div class="header">
    <div class="container">
        <div class="top-link">
            <!--<a href="/login.html" class="member"><img src="skin/images/header-login.png"></a>-->
            <a data-toggle="modal" data-target="#myloginModal" class="member"><img src="skin/images/header-login.png"></a>
            <a href="https://iets.group/" class="member"><img src="skin/images/header-re.png"></a>
            <!-- <a href="/about.html?nid=5facf7583c34ca2687adb1fd">Join</a> -->
            <a href="/about.html?nid=5face83ab277840ee069311e">Contact us </a>
        </div>
        <a href="/" class="logo"><img src="skin/images/logo2.png"></a>
        <button type="button" class="navbar-toggle">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
    </div>
</div>
<div class="nav">
    <div class="container">
        <ul class="daohang clearfix">
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
            <li class="nav-item" style="filter: blur(10px);"><a href="javascript:;"><img src="skin/images/home.png"> </a> </li>
        </ul>
        <div class="search">
            <div class="searchbox">
                <input class="search_kang" name="keywords" type="text"><img src="skin/images/search.png"
                    class="search_image1"><input type="image" name="search_image" class="search_image"
                    src="skin/images/search.png">
            </div>
        </div>
    </div>
</div>

<script id="navTemplate" type="text/html"> 
    <li class="nav-item"><a href="/"><img src="skin/images/home.png"> </a> </li>
    <# each tree as node i #>
    <li class="nav-item">
        <a id="<# node.id #>" href="<# node.linkurl #>"><# node.title #> <span><img src="skin/images/nav-arrow.png"></span></a>
        <# if node.children != null && node.children.length > 0 #>
        <ul class="nav2">
            <# each node.children as node2 i #>
            <li>
                <nobr><a id="<# node2.id #>" href="<# node2.linkurl #>"><# node2.title #></a></nobr>
            </li>
            <#/each#>
        </ul>
        <#/if#>
    </li>
    <#/each#>
</script>

<!--登录-->
<div class="modal fade" id="myloginModal" tabindex="-1" role="dialog" aria-labelledby="myLoginModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-body">

            




                <div class="sign-content popup-in-content">
                    <div class="popup-in-txt">
                        <h2>sign in</h2>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="signin-form">
                                    <form action="signin.html">
                                        <div class="form-group">
                                            <label for="signin_form">User Name</label>
                                            <input name="username" class="form-control" id="signin_form" placeholder="User Name" autocomplete="off">
                                        </div><!--/.form-group -->
                                        <div class="form-group">
                                            <label for="signin_form">passward</label>
                                            <input name="userpass" type="password" class="form-control" id="signin_form" placeholder="Enter your password" autocomplete="off">
                                        </div><!--/.form-group -->
                                    </form><!--/form -->
                                </div><!--/.signin-form -->
                            </div><!--/.col -->
                        </div><!--/.row -->

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="signin-password">
                                    <div class="password-circle">
                                        <div class="single-password-circle">
                                            <input type="radio" id="radio01" name="radio">
                                                <label for="radio01">
                                                    <span class="round-boarder">
                                                        <span class="round-boarder1"></span>
                                                    </span>remember password
                                                </label>
                                        </div><!--/.single-password-circle-->
                                        <div class="single-forgot-password-circle text-right">
                                            <a href="/forgetPassword.html">Forgot email or password</a>
                                        </div><!--/.single-password-circle-->
                                    </div><!--/.password-circle-->
                                </div><!--/.signin-password -->
                            </div><!--/.col -->
                        </div><!--/.row -->

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="signin-footer">
                                    <button type="button" class="btn signin_btn signin_btn_two ajax-login" data-toggle="modal" data-target=".signin_modal">
                                        sign in
                                    </button>
                                    <p>
                                        Don’t have an Account ?
                                        <a href="/register.html" style="float:right">register</a>
                                    </p>
                                </div><!--/.signin-footer -->
                            </div><!--/.col -->
                        </div><!--/.row -->
                    </div><!-- .popup-in-txt -->
                </div>



            </div>
        </div>
    </div>
</div>`);


require(['../js/site', '../js/global','artTemplate', 'bootstrap'],function (main, main2){ 
    main2.Login()
    main.Nav.intactTree();
    var member = $.isigStorage("MEETING-MEMBER")
    if(!$.isNullOrEmpty(member)){
        member = JSON.parse($.isigStorage("MEETING-MEMBER"))
        $(".top-link").prepend("<a href='/information.html?a=1' class='uc'>"+member.givenname+" "+member.surname+"</a>")
    }else{
        $(".top-link a").removeClass("member")
    }
    
});